import * as i0 from '@angular/core';
import { inject, signal, effect, Directive, Input, input, computed, untracked, Component, ChangeDetectionStrategy, HostBinding, Renderer2 } from '@angular/core';
import { CrmUnsubscribeDirective, CrmBreakpointSpanDirective } from 'common-module/core';
import { CrmFormStore } from 'common-module/form/store';
import { Subject, switchMap, tap, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NgStyle } from '@angular/common';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzFormItemComponent, NzFormLabelComponent, NzFormControlComponent } from 'ng-zorro-antd/form';
import { NzRowDirective } from 'ng-zorro-antd/grid';
import { crmResolveRenderItem, CrmRenderer2Directive } from 'common-module/renderer';
import { FormGroup, FormArray } from '@angular/forms';
import { NzDividerComponent } from 'ng-zorro-antd/divider';

/**
 * @deprecated will be removed in v9
 * @see {CrmFormDataInput2Component}
 */
const _c0 = ["*"];
function CrmFormDataWrapperComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nz-form-label", 1);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_1_0;
    let tmp_3_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("breakpointConfig", (tmp_1_0 = ctx_r0.config.layout == null ? null : ctx_r0.config.layout.label) !== null && tmp_1_0 !== undefined ? tmp_1_0 : 24)("nzNoColon", ctx_r0.config.layout == null ? null : ctx_r0.config.layout.noColon)("nzLabelAlign", (tmp_3_0 = ctx_r0.config.layout == null ? null : ctx_r0.config.layout.labelAlign) !== null && tmp_3_0 !== undefined ? tmp_3_0 : "right");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 4, ctx_r0.config.label), " ");
  }
}
function CrmFormDataWrapper2Component_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "nz-form-label", 1);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "crmTranslate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("breakpointConfig", ctx_r0.layout().label)("nzNoColon", ctx_r0.layout().noColon)("nzLabelAlign", ctx_r0.layout().labelAlign);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 4, ctx), " ");
  }
}
function CrmFormFragmentComponent_Conditional_0_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 0);
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("crmRenderer2", ctx)("context", item_r1.render == null ? null : item_r1.render.contentContext);
  }
}
function CrmFormFragmentComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmFormFragmentComponent_Conditional_0_Conditional_0_Template, 1, 2, "ng-container", 0);
  }
  if (rf & 2) {
    let tmp_2_0;
    const item_r1 = ctx;
    i0.ɵɵconditional((tmp_2_0 = item_r1.render == null ? null : item_r1.render.content) ? 0 : -1, tmp_2_0);
  }
}
const _forTrack0 = ($index, $item) => $item.name;
function CrmFormGroupComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nz-divider", 1);
    i0.ɵɵpipe(1, "crmTranslate");
  }
  if (rf & 2) {
    const divider_r1 = ctx;
    i0.ɵɵproperty("nzOrientation", divider_r1.orientation)("nzText", i0.ɵɵpipeBind1(1, 2, divider_r1.text));
  }
}
function CrmFormGroupComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3, 0);
    i0.ɵɵelement(2, "crm-form-fragment", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const child_r2 = ctx.$implicit;
    const parent_r3 = i0.ɵɵreference(1);
    i0.ɵɵproperty("breakpointConfig", child_r2.layout.span);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("parent", parent_r3)("form", child_r2.group)("data", child_r2.data)("fragment", child_r2.fragment);
  }
}
function CrmFormArrayComponent_For_2_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementContainer(1, 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const render_r1 = ctx;
    const item_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("breakpointConfig", item_r2.layout.span);
    i0.ɵɵadvance();
    i0.ɵɵproperty("crmRenderer2", render_r1.content)("context", render_r1.contentContext);
  }
}
function CrmFormArrayComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, CrmFormArrayComponent_For_2_Conditional_0_Template, 2, 3, "div", 1);
  }
  if (rf & 2) {
    let tmp_11_0;
    const item_r2 = ctx.$implicit;
    i0.ɵɵconditional((tmp_11_0 = item_r2.render) ? 0 : -1, tmp_11_0);
  }
}
class CrmFormDataInputComponent extends CrmUnsubscribeDirective {
  constructor() {
    super();
    this.errorSubject$ = new Subject();
    this.mode = inject(CrmFormStore, {
      optional: true
    })?.mode ?? signal('edit');
    effect(() => this.onModeChange(this.mode()), {
      allowSignalWrites: true
    });
  }
  ngOnInit() {
    this.initControl();
    this.error$ = this.errorSubject$.asObservable();
  }
  initControl() {
    if (!this.control) {
      this.control = this.form.controls[this.name];
    }
    this.config ??= {};
    this.status$ = this.untilDestroyed(this.control.statusChanges).pipe(filter(() => this.control.dirty), map(status => {
      switch (status) {
        case 'INVALID':
          this.emitError();
          return 'error';
        case 'PENDING':
          return 'validating';
        default:
          return 'success';
      }
    }));
  }
  emitError() {
    const error = Object.keys(this.control.errors ?? {})[0];
    if (!error) {
      this.errorSubject$.next(null);
    }
    const {
      validators = [],
      asyncValidators = []
    } = this.config;
    const validator = validators?.find(v => v.name === error);
    if (validator) {
      this.errorSubject$.next(validator.error);
      return;
    }
    const asyncValidator = asyncValidators?.find(v => v.name === error);
    if (asyncValidator) {
      this.errorSubject$.next(asyncValidator.error);
      return;
    }
  }
  onModeChange(mode) {
    switch (mode) {
      case 'read':
        this.onReadMode();
        break;
      case 'edit':
        this.onEditMode();
        break;
    }
  }
  onReadMode() {
    this.control?.setValue(this.data, {
      onlySelf: true
    });
    if (this.config.changeDisabledStateOnModeChange) {
      this.control?.disable({
        onlySelf: true
      });
    }
    this.control?.markAsPristine();
  }
  onEditMode() {
    if (this.config.changeDisabledStateOnModeChange) {
      this.control?.enable({
        onlySelf: true
      });
    }
  }
  static {
    this.ɵfac = function CrmFormDataInputComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormDataInputComponent)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmFormDataInputComponent,
      inputs: {
        form: "form",
        name: "name",
        data: "data",
        config: "config",
        control: "control"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormDataInputComponent, [{
    type: Directive
  }], () => [], {
    form: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    data: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    control: [{
      type: Input
    }]
  });
})();
class CrmFormDataInput2Component extends CrmUnsubscribeDirective {
  constructor() {
    super();
    this.form = input.required();
    this.name = input.required();
    this.data = input();
    this.config = input();
    this.control = input();
    this.resolvedControl = computed(() => this.control() ?? this.form().controls[this.name()]);
    this.resolvedConfig = computed(() => this.config() ?? {});
    this.mode = inject(CrmFormStore, {
      optional: true
    })?.mode ?? signal('edit');
    this.status$ = toObservable(computed(() => this.resolvedControl())).pipe(switchMap(control => control.statusChanges.pipe(map(status => ({
      control,
      status
    })))), filter(({
      control
    }) => control.dirty), map(inputs => this.mapStatus(inputs)));
    this.status = toSignal(this.status$);
    this.errorSubject$ = new Subject();
    this.error$ = this.errorSubject$.asObservable();
    this.error = toSignal(this.error$);
    effect(() => {
      const mode = this.mode();
      untracked(() => this.onModeChange(mode));
    });
  }
  emitError(control) {
    const error = Object.keys(control.errors ?? {})[0];
    if (!error) {
      this.errorSubject$.next(null);
    }
    const {
      validators = [],
      asyncValidators = []
    } = this.resolvedConfig();
    const validator = validators?.find(v => v.name === error);
    if (validator) {
      this.errorSubject$.next(validator.error);
      return;
    }
    const asyncValidator = asyncValidators?.find(v => v.name === error);
    if (asyncValidator) {
      this.errorSubject$.next(asyncValidator.error);
      return;
    }
  }
  onModeChange(mode) {
    switch (mode) {
      case 'read':
        this.onReadMode();
        break;
      case 'edit':
        this.onEditMode();
        break;
    }
  }
  onReadMode() {
    const control = this.resolvedControl();
    control.setValue(this.data(), {
      onlySelf: true
    });
    if (this.resolvedConfig().changeDisabledStateOnModeChange) {
      control.disable({
        onlySelf: true
      });
    }
    control.markAsPristine();
  }
  onEditMode() {
    const control = this.resolvedControl();
    if (this.resolvedConfig().changeDisabledStateOnModeChange) {
      control.enable({
        onlySelf: true
      });
    }
  }
  mapStatus(inputs) {
    switch (inputs.status) {
      case 'INVALID':
        this.emitError(inputs.control);
        return 'error';
      case 'PENDING':
        return 'validating';
      default:
        return 'success';
    }
  }
  static {
    this.ɵfac = function CrmFormDataInput2Component_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormDataInput2Component)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CrmFormDataInput2Component,
      inputs: {
        form: [1, "form"],
        name: [1, "name"],
        data: [1, "data"],
        config: [1, "config"],
        control: [1, "control"]
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormDataInput2Component, [{
    type: Directive
  }], () => [], null);
})();

/**
 * @deprecated will be removed in v9
 * @see {CrmFormDataWrapper2Component}
 */
class CrmFormDataWrapperComponent {
  constructor() {
    this.hostClass = 'crm-form-data-input-wrapper';
  }
  static {
    this.ɵfac = function CrmFormDataWrapperComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormDataWrapperComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormDataWrapperComponent,
      selectors: [["crm-form-data-input-wrapper"]],
      hostVars: 2,
      hostBindings: function CrmFormDataWrapperComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        status: "status",
        error: "error",
        config: "config"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 5,
      vars: 10,
      consts: [[1, "crm-form-item", 3, "nzJustify", "nzAlign", "nzGutter", "ngStyle"], ["crmBreakpointSpan", "", 1, "crm-form-data-input-label", 3, "breakpointConfig", "nzNoColon", "nzLabelAlign"], ["crmBreakpointSpan", "", 1, "crm-form-data-input-control", 3, "nzValidateStatus", "nzErrorTip", "breakpointConfig"]],
      template: function CrmFormDataWrapperComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "nz-form-item", 0);
          i0.ɵɵtemplate(1, CrmFormDataWrapperComponent_Conditional_1_Template, 3, 6, "nz-form-label", 1);
          i0.ɵɵelementStart(2, "nz-form-control", 2);
          i0.ɵɵpipe(3, "crmTranslate");
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_0_0;
          let tmp_1_0;
          let tmp_2_0;
          let tmp_5_0;
          let tmp_7_0;
          i0.ɵɵproperty("nzJustify", (tmp_0_0 = ctx.config.layout == null ? null : ctx.config.layout.justify) !== null && tmp_0_0 !== undefined ? tmp_0_0 : null)("nzAlign", (tmp_1_0 = ctx.config.layout == null ? null : ctx.config.layout.align) !== null && tmp_1_0 !== undefined ? tmp_1_0 : null)("nzGutter", (tmp_2_0 = ctx.config.layout == null ? null : ctx.config.layout.gutter) !== null && tmp_2_0 !== undefined ? tmp_2_0 : 0)("ngStyle", ctx.config.layout == null ? null : ctx.config.layout.style);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.config.label ? 1 : -1);
          i0.ɵɵadvance();
          i0.ɵɵproperty("nzValidateStatus", (tmp_5_0 = ctx.status) !== null && tmp_5_0 !== undefined ? tmp_5_0 : "")("nzErrorTip", i0.ɵɵpipeBind1(3, 8, ctx.error))("breakpointConfig", (tmp_7_0 = ctx.config.layout == null ? null : ctx.config.layout.input) !== null && tmp_7_0 !== undefined ? tmp_7_0 : 24);
        }
      },
      dependencies: [CrmTranslatePipe, NzFormItemComponent, NzRowDirective, NgStyle, NzFormLabelComponent, CrmBreakpointSpanDirective, NzFormControlComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormDataWrapperComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-data-input-wrapper',
      template: `
    <nz-form-item
      [nzJustify]="config.layout?.justify ?? null"
      [nzAlign]="config.layout?.align ?? null"
      [nzGutter]="config.layout?.gutter ?? 0"
      class="crm-form-item"
      [ngStyle]="config.layout?.style"
    >
      @if (config.label) {
        <nz-form-label
          class="crm-form-data-input-label"
          crmBreakpointSpan
          [breakpointConfig]="config.layout?.label ?? 24"
          [nzNoColon]="config.layout?.noColon"
          [nzLabelAlign]="config.layout?.labelAlign ?? 'right'"
        >
          {{ config.label | crmTranslate }}
        </nz-form-label>
      }

      <nz-form-control
        class="crm-form-data-input-control"
        [nzValidateStatus]="status ?? ''"
        [nzErrorTip]="error | crmTranslate"
        crmBreakpointSpan
        [breakpointConfig]="config.layout?.input ?? 24"
      >
        <ng-content></ng-content>
      </nz-form-control>
    </nz-form-item>
  `,
      imports: [CrmTranslatePipe, NzFormItemComponent, NzRowDirective, NgStyle, NzFormLabelComponent, CrmBreakpointSpanDirective, NzFormControlComponent],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    status: [{
      type: Input
    }],
    error: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormDataWrapper2Component {
  constructor() {
    this.status = input();
    this.error = input();
    this.config = input();
    this.label = computed(() => this.config()?.label);
    this.validateStatus = computed(() => this.status() ?? '');
    this.layout = computed(() => {
      const {
        layout
      } = this.config() ?? {};
      return {
        gutter: layout?.gutter ?? 0,
        align: layout?.align ?? null,
        justify: layout?.justify ?? null,
        style: layout?.style ?? {},
        label: layout?.label ?? 24,
        input: layout?.input ?? 24,
        labelAlign: layout?.labelAlign ?? 'right',
        noColon: layout?.noColon
      };
    });
    this.hostClass = 'crm-form-data-input-wrapper';
  }
  static {
    this.ɵfac = function CrmFormDataWrapper2Component_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormDataWrapper2Component)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormDataWrapper2Component,
      selectors: [["crm-form-data-input-wrapper2"]],
      hostVars: 2,
      hostBindings: function CrmFormDataWrapper2Component_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        status: [1, "status"],
        error: [1, "error"],
        config: [1, "config"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 5,
      vars: 10,
      consts: [[1, "crm-form-item", 3, "nzJustify", "nzAlign", "nzGutter", "ngStyle"], ["crmBreakpointSpan", "", 1, "crm-form-data-input-label", 3, "breakpointConfig", "nzNoColon", "nzLabelAlign"], ["crmBreakpointSpan", "", 1, "crm-form-data-input-control", 3, "breakpointConfig", "nzValidateStatus", "nzErrorTip"]],
      template: function CrmFormDataWrapper2Component_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "nz-form-item", 0);
          i0.ɵɵtemplate(1, CrmFormDataWrapper2Component_Conditional_1_Template, 3, 6, "nz-form-label", 1);
          i0.ɵɵelementStart(2, "nz-form-control", 2);
          i0.ɵɵpipe(3, "crmTranslate");
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          let tmp_4_0;
          i0.ɵɵproperty("nzJustify", ctx.layout().justify)("nzAlign", ctx.layout().align)("nzGutter", ctx.layout().gutter)("ngStyle", ctx.layout().style);
          i0.ɵɵadvance();
          i0.ɵɵconditional((tmp_4_0 = ctx.label()) ? 1 : -1, tmp_4_0);
          i0.ɵɵadvance();
          i0.ɵɵproperty("breakpointConfig", ctx.layout().input)("nzValidateStatus", ctx.validateStatus())("nzErrorTip", i0.ɵɵpipeBind1(3, 8, ctx.error()));
        }
      },
      dependencies: [CrmTranslatePipe, NzFormItemComponent, NzRowDirective, NgStyle, NzFormLabelComponent, CrmBreakpointSpanDirective, NzFormControlComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormDataWrapper2Component, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-data-input-wrapper2',
      template: `
    <nz-form-item
      [nzJustify]="layout().justify"
      [nzAlign]="layout().align"
      [nzGutter]="layout().gutter"
      [ngStyle]="layout().style"
      class="crm-form-item"
    >
      @if (label(); as label) {
        <nz-form-label
          class="crm-form-data-input-label"
          crmBreakpointSpan
          [breakpointConfig]="layout().label"
          [nzNoColon]="layout().noColon"
          [nzLabelAlign]="layout().labelAlign"
        >
          {{ label | crmTranslate }}
        </nz-form-label>
      }

      <nz-form-control
        class="crm-form-data-input-control"
        crmBreakpointSpan
        [breakpointConfig]="layout().input"
        [nzValidateStatus]="validateStatus()"
        [nzErrorTip]="error() | crmTranslate"
      >
        <ng-content></ng-content>
      </nz-form-control>
    </nz-form-item>
  `,
      imports: [CrmTranslatePipe, NzFormItemComponent, NzRowDirective, NgStyle, NzFormLabelComponent, CrmBreakpointSpanDirective, NzFormControlComponent],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormFragmentComponent {
  constructor() {
    this.hostClass = 'crm-form-fragment';
    this.parent = input.required();
    this.form = input.required();
    this.fragment = input.required();
    this.data = input();
    this.renderer = inject(Renderer2);
    this.view = computed(() => {
      const {
        content,
        contentContext,
        name,
        children,
        type,
        visible$
      } = this.fragment();
      const context = {
        ...contentContext,
        name,
        form: this.form(),
        data: this.data()
      };
      if (type === 'group' || type === 'array') {
        context['config'] = {
          children: children ?? []
        };
      }
      return {
        render: crmResolveRenderItem({
          content,
          contentContext: context
        }),
        form: this.form(),
        name,
        visible$
      };
    });
    this.item$ = toObservable(computed(() => this.view())).pipe(switchMap(item => {
      return this.resolveItem(item);
    }), tap(({
      visible,
      item: {
        form,
        name
      }
    }) => {
      this.markControl(visible, form, name);
    }), map(({
      visible,
      item
    }) => {
      return visible ? item : undefined;
    }));
    this.item = toSignal(this.item$);
  }
  resolveItem(item) {
    if (!item.visible$) {
      return of({
        item,
        visible: true
      });
    }
    return item.visible$(item.form).pipe(map(visible => ({
      item,
      visible
    })));
  }
  markControl(visible, form, name) {
    const control = form.controls[name];
    if (visible) {
      this.renderer.removeStyle(this.parent(), 'display');
    } else {
      this.renderer.setStyle(this.parent(), 'display', 'none');
      control?.reset();
    }
  }
  static {
    this.ɵfac = function CrmFormFragmentComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormFragmentComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormFragmentComponent,
      selectors: [["crm-form-fragment"]],
      hostVars: 2,
      hostBindings: function CrmFormFragmentComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        parent: [1, "parent"],
        form: [1, "form"],
        fragment: [1, "fragment"],
        data: [1, "data"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      consts: [[3, "crmRenderer2", "context"]],
      template: function CrmFormFragmentComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmFormFragmentComponent_Conditional_0_Template, 1, 1);
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = ctx.item()) ? 0 : -1, tmp_0_0);
        }
      },
      dependencies: [CrmRenderer2Directive],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormFragmentComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-fragment',
      template: `
    @if (item(); as item) {
      @if (item.render?.content; as content) {
        <ng-container
          [crmRenderer2]="content"
          [context]="item.render?.contentContext"
        ></ng-container>
      }
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmRenderer2Directive]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class CrmFormGroupComponent {
  constructor() {
    this.form = input.required();
    this.name = input.required();
    this.data = input();
    this.divider = input();
    this.config = input.required();
    this.hostClass = 'crm-form-group';
    this.resolvedDivider = computed(() => {
      const divider = this.divider();
      if (!divider) {
        return undefined;
      }
      return {
        orientation: divider.orientation ?? 'left',
        text: divider.text
      };
    });
    this.layout = computed(() => {
      const {
        layout
      } = this.config();
      return {
        gutter: layout?.gutter ?? [16, 16],
        align: layout?.align ?? null,
        justify: layout?.justify ?? null
      };
    });
    this.group = computed(() => {
      const {
        children
      } = this.config();
      const group = this.form().controls[this.name()];
      const data = this.data();
      if (group instanceof FormGroup) {
        return children.map(child => ({
          fragment: child,
          layout: {
            span: child.layout?.span ?? 24
          },
          group: group,
          data: data?.[child.name],
          name: child.name
        }));
      }
      throw new Error(`${group} is not a group control`);
    });
  }
  static {
    this.ɵfac = function CrmFormGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormGroupComponent,
      selectors: [["crm-form-group"]],
      hostVars: 2,
      hostBindings: function CrmFormGroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        form: [1, "form"],
        name: [1, "name"],
        data: [1, "data"],
        divider: [1, "divider"],
        config: [1, "config"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 4,
      consts: [["parent", ""], [3, "nzOrientation", "nzText"], ["nz-row", "", 3, "nzGutter", "nzAlign", "nzJustify"], ["crmBreakpointSpan", "", 3, "breakpointConfig"], [3, "parent", "form", "data", "fragment"]],
      template: function CrmFormGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmFormGroupComponent_Conditional_0_Template, 2, 4, "nz-divider", 1);
          i0.ɵɵelementStart(1, "div", 2);
          i0.ɵɵrepeaterCreate(2, CrmFormGroupComponent_For_3_Template, 3, 5, "div", 3, _forTrack0);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_0_0;
          i0.ɵɵconditional((tmp_0_0 = ctx.resolvedDivider()) ? 0 : -1, tmp_0_0);
          i0.ɵɵadvance();
          i0.ɵɵproperty("nzGutter", ctx.layout().gutter)("nzAlign", ctx.layout().align)("nzJustify", ctx.layout().justify);
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.group());
        }
      },
      dependencies: [CrmFormFragmentComponent, CrmTranslatePipe, NzDividerComponent, NzRowDirective, CrmBreakpointSpanDirective],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormGroupComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-group',
      template: `
    @if (resolvedDivider(); as divider) {
      <nz-divider
        [nzOrientation]="divider.orientation"
        [nzText]="divider.text | crmTranslate"
      ></nz-divider>
    }

    <div
      nz-row
      [nzGutter]="layout().gutter"
      [nzAlign]="layout().align"
      [nzJustify]="layout().justify"
    >
      @for (child of group(); track child.name) {
        <div crmBreakpointSpan [breakpointConfig]="child.layout.span" #parent>
          <crm-form-fragment
            [parent]="parent"
            [form]="child.group"
            [data]="child.data"
            [fragment]="child.fragment"
          ></crm-form-fragment>
        </div>
      }
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmFormFragmentComponent, CrmTranslatePipe, NzDividerComponent, NzRowDirective, CrmBreakpointSpanDirective]
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns group form fragment
 *
 * @param name
 * @param children
 * @param divider
 * @param layout
 * @param visible$
 */
const crmGetFormGroup = ({
  name,
  children,
  divider,
  layout,
  visible$
}) => ({
  type: 'group',
  name,
  content: CrmFormGroupComponent,
  contentContext: {
    divider
  },
  children,
  layout,
  visible$
});
class CrmFormArrayComponent {
  constructor() {
    this.form = input.required();
    this.name = input.required();
    this.data = input.required();
    this.config = input.required();
    this.hostClass = 'crm-form-array';
    this.layout = computed(() => {
      const {
        layout
      } = this.config();
      return {
        gutter: layout?.gutter ?? [16, 16],
        align: layout?.align ?? null,
        justify: layout?.justify ?? null
      };
    });
    this.array = computed(() => {
      const {
        content,
        contentContext,
        layout,
        name,
        type,
        children
      } = this.config().children[0];
      const array = this.form().controls[this.name()];
      const data = this.data();
      if (array instanceof FormArray) {
        return array.controls.map((control, index) => {
          const context = {
            ...contentContext,
            name
          };
          if (type === 'group') {
            context['form'] = control;
            context['config'] = {
              ...contentContext,
              children
            };
            context['data'] = data?.[index]?.[name];
          } else {
            context['control'] = control;
            context['data'] = data?.[index];
          }
          return {
            render: crmResolveRenderItem({
              content,
              contentContext: context
            }),
            layout: {
              span: layout?.span ?? 24
            }
          };
        });
      }
      throw new Error(`${this.name()} is not an array control.`);
    });
  }
  static {
    this.ɵfac = function CrmFormArrayComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CrmFormArrayComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmFormArrayComponent,
      selectors: [["crm-form-array"]],
      hostVars: 2,
      hostBindings: function CrmFormArrayComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
        }
      },
      inputs: {
        form: [1, "form"],
        name: [1, "name"],
        data: [1, "data"],
        config: [1, "config"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 3,
      consts: [["nz-row", "", 3, "nzGutter", "nzAlign", "nzJustify"], ["crmBreakpointSpan", "", 3, "breakpointConfig"], [3, "crmRenderer2", "context"]],
      template: function CrmFormArrayComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵrepeaterCreate(1, CrmFormArrayComponent_For_2_Template, 1, 1, null, null, i0.ɵɵrepeaterTrackByIndex);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("nzGutter", ctx.layout().gutter)("nzAlign", ctx.layout().align)("nzJustify", ctx.layout().justify);
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.array());
        }
      },
      dependencies: [NzRowDirective, CrmBreakpointSpanDirective, CrmRenderer2Directive],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmFormArrayComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-form-array',
      template: `
    <div
      nz-row
      [nzGutter]="layout().gutter"
      [nzAlign]="layout().align"
      [nzJustify]="layout().justify"
    >
      @for (item of array(); track idx; let idx = $index) {
        @if (item.render; as render) {
          <div crmBreakpointSpan [breakpointConfig]="item.layout.span">
            <ng-container
              [crmRenderer2]="render.content"
              [context]="render.contentContext"
            ></ng-container>
          </div>
        }
      }
    </div>
  `,
      imports: [NzRowDirective, CrmBreakpointSpanDirective, CrmRenderer2Directive],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Function which returns array form fragment
 *
 * @param name
 * @param children
 * @param prefilled
 * @param layout
 * @param visible$
 */
const crmGetFormArray = ({
  name,
  children,
  prefilled,
  layout,
  visible$
}) => ({
  type: 'array',
  name,
  content: CrmFormArrayComponent,
  children,
  prefilled,
  layout,
  visible$
});

/**
 * Generated bundle index. Do not edit.
 */

export { CrmFormArrayComponent, CrmFormDataInput2Component, CrmFormDataInputComponent, CrmFormDataWrapper2Component, CrmFormDataWrapperComponent, CrmFormFragmentComponent, CrmFormGroupComponent, crmGetFormArray, crmGetFormGroup };
